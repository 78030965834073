




































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Loading from '@/components/shared/Loading.vue';
import PackageList from '@/components/packages/PackageList.vue';
import {
  FETCH_ALL_PACKAGES,
  FETCH_PACKAGES,
  GET_LOADING, GET_USER,
  GET_USER_WORKSPACES,
} from '@/store/types';
import { emptyWorkspace, Workspace } from '@/store/workspace/models';
import FailedMessage from '@/components/shared/FailedMessage.vue';
import { namespaces } from '@/scripts/namespaces';

const namespace = 'pkg';
const userNamespace = 'user';
const workspaceNamespace = 'workspace';

@Component({
  components: {
    Loading,
    PackageList,
    FailedMessage,
  },
})
export default class Packages extends Vue {
  // Getters
  @Getter(GET_LOADING, { namespace: namespaces.PACKAGE }) getLoading: any;

  @Getter(GET_USER, { namespace: namespaces.USER }) getUser: any;

  @Getter(GET_USER_WORKSPACES, { namespace: namespaces.WORKSPACE }) getWorkspaces: any;

  // Actions
  @Action(FETCH_ALL_PACKAGES, { namespace: namespaces.PACKAGE }) fetchPackages: any;

  @Action(FETCH_PACKAGES, { namespace: namespaces.BRICK }) fetchInstalledPackages: any;

  // Data
  private workspaceId: string = this.$route.params.workspaceId;

  private failedToFetchMessage: string = '';

  // Computed
  get validateUser() {
    const { workspaceId } = this.$route.params;
    const userId = this.getUser.id;
    if (!this.getWorkspaces || !userId || !workspaceId) return false;
    const workspaceIndex = this.getWorkspaces.findIndex((w: Workspace) => workspaceId === w.id
     && w.owner.id === userId);
    return workspaceIndex !== -1;
  }

  get workspace(): Workspace {
    const { workspaceId } = this.$route.params;
    if (this.getWorkspaces) {
      return this.getWorkspaces.find((w: Workspace) => w.id === workspaceId);
    }

    return emptyWorkspace;
  }

  @Watch('getWorkspaces')
  async onWorkspaceChange() {
    if (this.validateUser) {
      await this.fetch();
    }
  }

  // Vue Life Cycle Hooks
  created() {
    this.$nextTick(async () => {
      const isUserAuthorized = this.validateUser;
      if (isUserAuthorized) {
        await this.fetch();
      }
    });
  }

  async fetch() {
    await this.fetchInstalledPackages(this.workspace.owner.id).catch((e: any) => {
      this.failedToFetchMessage = e.message;
    });

    await this.fetchPackages().catch((err: any) => {
      this.failedToFetchMessage = err.message;
    });
  }
}

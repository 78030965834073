










































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import Profile from '@/components/user/Profile.vue';
import ChangePassword from '@/components/user/ChangePassword.vue';
import DeleteAccount from '@/components/user/DeleteAccount.vue';

const namespace: string = 'user';

@Component({
  components: {
    Profile,
    ChangePassword,
    DeleteAccount,
  },
})
export default class UserAccountBody extends Vue {
  @Getter(types.GET_USER, { namespace }) getUser: any;

  @Action(types.FETCH_LOGGED_IN_USER, { namespace }) fetchLoggedInUser: any;

  async created() {
    await this.fetchLoggedInUser();
  }
}

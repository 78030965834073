

























































































































































import { Validations } from 'vuelidate-property-decorators';
import { required, minLength } from 'vuelidate/lib/validators';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/store/user/models';
import { TranslateResult } from 'vue-i18n';
import { Action } from 'vuex-class';
import * as types from '@/store/types';
import { EMAIL_REGEX, USERNAME_REGEX } from '@/scripts/shared';
import Avatar from '@/components/user/Avatar.vue';

const namespace: string = 'user';

@Component({
  components: {
    Avatar,
  },
})
export default class Profile extends Vue {
  @Prop() getUser: any;

  @Action(types.UPDATE_USER, { namespace }) updateUser: any;

  private isEditUser: boolean = false;

  private email: string = '';

  private username: string = '';

  private password: string = '';

  private loading: boolean = false;

  private showPassword: boolean = false;

  private isEmailChanged: boolean = false;

  @Validations()
  validations = {
    password: {
      required,
      minLength: minLength(8),
    },

    username: {
      required,
      matches(value: string) {
        return USERNAME_REGEX.test(value);
      },
    },

    email: {
      required,
      matches(value: string) {
        return EMAIL_REGEX.test(value);
      },
    },
  };

  get passwordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.password.$dirty) return errors;

    if (!this.$v.password.required) errors.push(this.$t('password_required_to_save_changes'));
    if (!this.$v.password.minLength) errors.push(this.$t('password_length'));

    return errors;
  }

  get usernameErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.username.$dirty) return errors;

    if (!this.$v.username.required) errors.push(this.$t('required_field'));
    if (!this.$v.username.matches) errors.push(this.$t('username_invalid'));

    return errors;
  }

  get emailErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.email.$dirty) return errors;

    if (!this.$v.email.required) errors.push(this.$t('required_field'));
    if (!this.$v.email.matches) errors.push(this.$t('email_invalid'));

    return errors;
  }

  /**
   * Turns on the edit mode
   * letting the user edit
   * their username and email
   */
  turnOnEditMode() {
    this.email = this.getUser.email;
    this.username = this.getUser.username;

    this.isEditUser = true;
  }

  /**
   * Turns off the edit mode
   * by clearning the password
   * and resetting form errors
   */
  turnOffEditMode() {
    this.password = '';
    this.$v.$reset();
    this.isEditUser = false;
  }

  /**
   * Validates the form
   * and submits the request
   * to update the user
   */
  async submitUpdate() {
    try {
      this.$v.$touch();

      if (this.getUser.email.trim().toLowerCase() !== this.email.trim().toLowerCase()) {
        this.isEmailChanged = true;
      }

      if (this.$v.$invalid) return;

      this.loading = true;

      await this.updateUser({
        user: {
          id: this.getUser.id,
          username: this.username,
          email: this.email,
          password: this.password,
        },
        currentEmail: this.getUser.email,
      });

      this.loading = false;
      this.turnOffEditMode();
    } catch (error) {
      // Just reset the password and form
      // so that the user can try again
      this.password = '';
      this.$v.$reset();
      this.loading = false;
    }
  }
}

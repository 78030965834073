



















































































































import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignupLoginLayout from '@/components/shared/layouts/SignupLoginLayout.vue';
import { EMAIL_REGEX, USERNAME_REGEX, PASSWORD_REGEX } from '@/scripts/shared';
import { Validations } from 'vuelidate-property-decorators';
import { required, sameAs } from 'vuelidate/lib/validators';
import { TranslateResult } from 'vue-i18n';
import * as types from '@/store/types';
import { verifySignupRequest } from '@/store/user/api';
import { ErrorLevel } from '@/store/errors/models';
import TermsAndConditionsDialog from '@/components/user/TermsAndConditionsDialog.vue';

const namespace: string = 'user';
const namespaceErr: string = 'error';

@Component({
  components: {
    SignupLoginLayout,
    TermsAndConditionsDialog,
  },
})
export default class Signup extends Vue {
  @Action(types.SIGNUP, { namespace })
  signup: any;

  @Action(types.SET_ERROR, { namespace: namespaceErr })
  setError: any;

  @Getter(types.GET_LOGIN_STATUS, { namespace })
  loginStatus: any;

  private username: string = '';

  private email: string = '';

  private password: string = '';

  private repeatPassword: string = '';

  private show = false;

  private token = '';

  private formSubmitted = false;

  private termsAndCondition: boolean = false

  @Validations()
  validations = {
    username: {
      required,
      matches(value: string) {
        return USERNAME_REGEX.test(value);
      },
    },
    email: {
      required,
      matches(value: string) {
        return EMAIL_REGEX.test(value);
      },
    },
    password: {
      required,
      matches(value: string) {
        return PASSWORD_REGEX.test(value);
      },
    },
    repeatPassword: {
      required,
      sameAs: sameAs('password'),
    },
    termsAndCondition: {
      sameAs: (val: any) => val === true,
    },
  };

  // On create component check if the id is included and also valid for signup
  async created() {
    if (!this.$route.query.id) {
      this.$router.push('/signupRequest');
      return;
    }
    const { id } = this.$route.query;
    if (id) {
      this.token = id.toString();
      await this.verifySignupLink();
    }
  }

  async verifySignupLink() {
    await verifySignupRequest(this.token)
      .then(({ status, data }) => {
        if (status === 200) {
          this.email = data;
        }
      })
      .catch(({ response }) => {
        this.setError({ error: response.data.toString(), level: ErrorLevel.ERROR });
        this.$router.push('/signupRequest');
      });
  }

  get emailErrors() {
    const errors: TranslateResult[] = [];
    if (!this.$v.email.$dirty) return errors;

    if (!this.$v.email.matches) errors.push(this.$t('email_invalid'));
    if (!this.$v.email.required) errors.push(this.$t('required_field'));
    return errors;
  }

  get passwordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.password.$dirty) return errors;

    if (!this.$v.password.required) errors.push(this.$t('required_field'));
    if (!this.$v.password.matches) {
      errors.push(this.$t('password_requirements'));
    }
    return errors;
  }

  get repeatPasswordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.repeatPassword.$dirty) return errors;

    if (!this.$v.repeatPassword.required) {
      errors.push(this.$t('required_field'));
    }
    if (!this.$v.repeatPassword.sameAs) {
      errors.push(this.$t('repeat_password_error'));
    }
    return errors;
  }

  get usernameErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.username.$dirty) return errors;

    if (!this.$v.username.matches) errors.push(this.$t('username_invalid'));
    if (!this.$v.username.required) errors.push(this.$t('required_field'));
    return errors;
  }

  get termsOfServiceErrors(): any {
    const errors = [];

    if (!this.$v.termsAndCondition.sameAs) errors.push(this.$t('required_field'));

    return errors;
  }

  get isSignupDisabled(): boolean {
    return this.$v.$invalid;
  }

  async handleSubmit() {
    this.formSubmitted = true;
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.formSubmitted = false;
      return;
    }

    await this.signup(
      {
        user: {
          username: this.username,
          email: this.email,
          password: this.password,
        },
        token: this.token,
      },

    );
    this.formSubmitted = false;
    if (this.loginStatus) {
      this.$router.push('/');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  signedTerms(signed: any) {
    this.termsAndCondition = signed;
  }
}

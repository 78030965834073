








































import { Component, Prop, Vue } from 'vue-property-decorator';
import marked from 'marked';
// eslint-disable-next-line import/no-webpack-loader-syntax
import terms_de from '!!raw-loader!./terms_conditions/terms-de.md';
// eslint-disable-next-line import/no-webpack-loader-syntax
import terms_en from '!!raw-loader!./terms_conditions/terms-en.md';

@Component
export default class TermsAndConditionsDialog extends Vue {
  private dialog: boolean = false

  private signing(status: boolean) {
    this.$emit('signed-terms', status);
    this.dialog = false;
  }

  get terms(): string {
    if (this.$i18n.locale === 'de') {
      return marked(terms_de);
    }
    return marked(terms_en);
  }
}




































































































































import { Validations } from 'vuelidate-property-decorators';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Action } from 'vuex-class';
import * as types from '@/store/types';
import { PASSWORD_REGEX } from '@/scripts/shared';
import { User } from '@/store/user/models';

const namespace: string = 'user';

@Component
export default class ChangePassword extends Vue {
  @Action(types.CHANGE_PASSWORD, { namespace }) changePassword: any;

  @Prop() getUser: any;

  private isEditPassword: boolean = false;

  private showPassword: boolean = false;

  private loading: boolean = false;

  private oldPassword: string = '';

  private newPassword: string = '';

  private repeatPassword: string = '';

  @Validations()
  validations = {
    oldPassword: {
      required,
      minLength: minLength(8),
    },

    newPassword: {
      required,
      matches(value: string) {
        return PASSWORD_REGEX.test(value);
      },
    },

    repeatPassword: {
      required,
      sameAs: sameAs('newPassword'),
    },
  };

  get oldPasswordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.oldPassword.$dirty) return errors;

    if (!this.$v.oldPassword.required) errors.push(this.$t('required_field'));
    if (!this.$v.oldPassword.minLength) errors.push(this.$t('password_length'));

    return errors;
  }

  get newPasswordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.newPassword.$dirty) return errors;

    if (!this.$v.newPassword.required) errors.push(this.$t('required_field'));
    if (!this.$v.newPassword.matches) errors.push(this.$t('password_requirements'));

    return errors;
  }

  get repeatPasswordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.repeatPassword.$dirty) return errors;

    if (!this.$v.repeatPassword.required) errors.push(this.$t('required_field'));
    if (!this.$v.repeatPassword.sameAs) errors.push(this.$t('repeat_password_error'));

    return errors;
  }

  /**
   * Submits a request to the
   * server to change the user's
   * password
   *
   * Clears the form and resets the
   * form error when update fails
   */
  async updatePassword() {
    try {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loading = true;

      const user: User = this.getUser;

      user.password = this.newPassword;

      await this.changePassword({
        oldPassword: this.oldPassword,
        user,
      });

      this.loading = false;
      this.isEditPassword = false;
      this.clearForm();
    } catch (error) {
      this.loading = false;
      this.clearForm();
    }
  }

  /**
   * Clears the password fields
   * and resets the error in the form
   */
  clearForm() {
    this.oldPassword = '';
    this.newPassword = '';
    this.repeatPassword = '';

    this.$v.$reset();
  }
}













import { Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PackageDetails from '@/components/packages/packageDetails/PackageDetails.vue';
import { namespaces } from '@/scripts/namespaces';
import { now } from 'jquery';

@Component({
  components: {
    PackageDetails,
  },
})
export default class PackageList extends Vue {
  @Prop() packages: any;

  @Prop() packageIndex: any;

  @Prop() selectedItemIndex: any;

  private selectedItem: string = '';

  private itemValues: any;

  async created() {
    this.itemValues = this.packages.map((x: any) => x.version);
    this.selectedItem = this.itemValues[this.selectedItemIndex];
  }

  changeSelected(selection: any) {
    this.$emit('on-version-change', this.packageIndex, this.itemValues.indexOf(selection));
  }
}

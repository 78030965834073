





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Package } from '@/store/bricks/models';
import { getVersionFromUrn } from '@/scripts/brickVersion';

const namespace: string = 'brick';

@Component
export default class PackageDialog extends Vue {
  @Prop() selectedPackage!: Package | undefined;

  getVersion = (urn: string) => getVersionFromUrn(urn)
}






































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BricksInPackage from '@/components/packages/packageDetails/BricksInPackage.vue';
import { getPackageIcon } from '@/scripts/bricks/getIcon';
import { Package } from '@/store/packages/models';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';

const namespace = 'pkg';
const namespace_brick: string = 'brick';
const namespaceUser: string = 'user';
@Component({
  components: {
    BricksInPackage,
  },
})
export default class PackageDetails extends Vue {
  // Props

  @Prop() pkg!: Package;

  @Prop() installed!: boolean;

  // Actions
  @Action(types.INSTALL_PACKAGE, { namespace: namespace_brick }) installPackage: any;

  // Data
  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  private expandDescription = false;

  private selectedBrick = null;

  private shouldShowReadMore = false;

  private installLoading = false;

  private isInstalled : boolean = false;

  // Vue Life Cycle Hooks
  created() {
    this.isInstalled = this.installed;
  }

  async mounted() {
    this.$nextTick(() => {
      this.analyseDescription();
    });
  }

  // Methods

  /**
   * Closes the details of a brick
   */
  clearBrick() {
    this.selectedBrick = null;
  }

  /**
   * Finds out if the size of the description
   * is long enough for the "Read More" button
   * to be shown
   */
  analyseDescription() {
    if (!this.pkg.description) {
      this.shouldShowReadMore = false;
    }

    const span: any = this.$refs.desc;

    if (span && span.scrollHeight > span.clientHeight) {
      this.shouldShowReadMore = true;
    }
  }

  /**
   * Returns the default icon or package
   * icon with proper BASE64 prefix
   *
   * @param pkg
   */
  getPackageIcon = (pkg: Package) => getPackageIcon(pkg.image);

  /**
   * Sends an installation request to the package manager
   *
   */
  async install() {
    this.installLoading = true;

    await this.installPackage({ userId: this.getUser.id, id: this.pkg.id });

    this.installLoading = false;
    this.isInstalled = true;
    this.$emit('force-render', this.pkg.id);
  }
}




















































import {
  Component, Watch, Vue, Prop,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';
import Toolbar from '@/components/shared/Toolbar.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';

const namespaceBrick: string = 'brick';

@Component({
  components: {
    Toolbar,
    Breadcrumb,
  },
})
export default class ToolbarBrick extends Vue {
  @Prop({ default: 'Bricks' }) title!: any

  @Action(types.SEARCH_BRICK, { namespace: namespaceBrick }) searchBrick: any;

  @Action(types.FILTER_BRICKS, { namespace: namespaceBrick }) filterBricks: any;

  @Getter(types.GET_BRICK_TYPES, { namespace: namespaceBrick })
  getBrickTypes: any;

  private name: string = '';

  public filter: string = '';

  private selectedFilter: string = 'filter_by_type';

  private changeFilter(filter: string) {
    this.selectedFilter = 'filter_by_type';
    if (filter) {
      this.selectedFilter = filter;
    }
    this.filterBricks({
      searchParam: this.name,
      filterParam: filter,
    });
  }

  @Watch('filter')
  onPropertyChanged(value: string, oldValue: string) {
    this.changeFilter(value);
  }

  @Watch('name')
  onNameChanged(value: string, oldValue: string) {
    const searchObj = {
      searchParam: this.name,
      filterParam: this.filter,
    };
    this.searchBrick(searchObj);
  }

  private resetFilter() {
    this.filter = '';
    this.selectedFilter = 'filter_by_type';
    this.filterBricks({
      searchParam: this.name,
      filterParam: '',
    });
  }
}

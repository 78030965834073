



























































import { Getter, Action } from 'vuex-class';
import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import * as types from '@/store/types';

const namespace: string = 'brick';
const namespaceUser: string = 'user';

@Component
export default class DeletePackage extends Vue {
  @Prop() name!: string;

  @Prop() id!: string;

  @Prop() dialog!: string;

  @Action(types.DELETE_PACKAGE, { namespace })
  deletePackage: any;

  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  private usedFlows: string = '';

  private canDelete: boolean = true;

  private loading: boolean = false;

  async remove() {
    this.loading = true;
    const res = await this.deletePackage({
      userId: this.getUser.id,
      packageToDelete: this.id,
    }).catch((err: any) => {
      this.loading = false;
      this.canDelete = false;
      if (err.response) {
        const message = this.getFlowsFromErrorMessage(err.response.data);
        this.usedFlows = message;
      }
    });
    this.loading = false;
    if (res === 200) {
      this.close();
    }
  }

  getFlowsFromErrorMessage = (error: string) => {
    let message = error;
    if (error.indexOf(':') !== -1) {
      message = error.slice(error.indexOf(':') + 1);
    }
    return message;
  };

  close() {
    this.$emit('close');
    this.canDelete = true;
  }

  goToFlows() {
    this.$router.push({ name: 'flows' });
  }
}

















































































import { Validations } from 'vuelidate-property-decorators';
import { required, minLength } from 'vuelidate/lib/validators';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Action } from 'vuex-class';
import * as types from '@/store/types';
import { User } from '../../store/user/models';

const namespace: string = 'user';
const namespace_flow: string = 'flow';
const namespace_bricks: string = 'brick';

@Component
export default class DeleteAccount extends Vue {
  @Prop() getUser: any;

  @Action(types.DELETE_USER, { namespace }) deleteUser: any;

  private isDeleteUser: boolean = false;

  private showPassword: boolean = false;

  private loading: boolean = false;

  private password: string = '';

  @Validations()
  validations = {
    password: {
      required,
      minLength: minLength(8),
    },
  };

  get passwordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.password.$dirty) return errors;

    if (!this.$v.password.required) errors.push(this.$t('required_field'));
    if (!this.$v.password.minLength) errors.push(this.$t('password_length'));

    return errors;
  }

  /**
   * Turns on the edit mode
   * letting the user edit
   * their username and email
   */
  turnOnEditMode() {
    this.isDeleteUser = true;
  }

  /**
   * Turns off the edit mode
   * by clearning the password
   * and resetting form errors
   */
  turnOffEditMode() {
    this.isDeleteUser = false;
    this.loading = false;
    this.$v.$reset();
    this.password = '';
  }

  async deleteAccount() {
    try {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loading = true;

      const user: User = {
        id: this.getUser.id,
        username: this.getUser.username,
        email: this.getUser.email,
        password: this.password,
        isAdmin: false,
        isAllowedToUpload: false,
      };
      const result = await this.deleteUser(user);
      if (result) {
        this.$router.push('/login');
      }
    } catch (error) {
      this.loading = false;
      this.$v.$reset();
      this.password = '';
    }
  }
}
